.marquee {
  position: relative;
  overflow: hidden;
  --move-initial: calc(0vw);
  --move-final: calc(-2340vw);
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 90s linear infinite;
  animation-play-state: running;
}

@media (min-width: 1024px) {
  .marquee {
    --move-final: calc(-520vw);
  }
  .marquee__inner {
    animation: marquee 60s linear infinite;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
